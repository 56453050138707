import { NextSeo } from 'next-seo';
import React from 'react';
import { GetServerSideProps } from 'next';
import config from '@/src/config';
import NotFoundPage from '@/components/templates/404/NotFoundPage';

const API_ENDPOINT =
  typeof window === 'undefined'
    ? config.get('SERVER_API_ENDPOINT')
    : config.get('API_ENDPOINT');

const testForSpecialityOrExpertise = speciality =>
  Promise.all([
    fetch(`${API_ENDPOINT}/pat-public/specialities/${speciality}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(res => res.ok),
    fetch(`${API_ENDPOINT}/pat-public/expertises/${speciality}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(res => res.ok),
  ]);

const SpecialityWithoutLocality = props => {
  const { error = false } = props;
  return error ? (
    <>
      <NextSeo noindex nofollow />
      <NotFoundPage />
    </>
  ) : null;
};

// TODO: use getStaticPaths
export const getServerSideProps: GetServerSideProps = async ({
  query: { speciality },
}) => {
  const results = await testForSpecialityOrExpertise(speciality);
  if (results.includes(true)) {
    const redirectUrl = `/${speciality}/75001-PARIS`;
    return {
      redirect: {
        destination: redirectUrl,
        permanent: true,
      },
    };
  }

  return { props: { error: true } };
};

export default SpecialityWithoutLocality;
