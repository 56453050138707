import React from 'react';
import { useRouter } from 'next/router';

import { Button, Stack } from '@mui/material';
import { IconChevronLeft } from '@maiia/design-system';
import Link from '../../atoms/Link/Link';

import { useTranslation } from '@/src/i18n';
import { APP_ROUTES } from '@/src/constants';
import useResponsive from '@/src/hooks/useResponsive';

/* Component */

const NotFoundPage = () => {
  const { t } = useTranslation();
  const { back } = useRouter();
  const { isMobile } = useResponsive();
  const showBackButton =
    typeof window !== 'undefined' && window.history.state.idx > 0;

  return (
    <div className="not-found page">
      <img
        src="/static/images/404.png"
        alt="Not Found"
        width="100%"
        height="100%"
      />
      <p>{t('page_not_found')}</p>
      <Stack
        direction={isMobile ? 'column-reverse' : 'row'}
        justifyContent={showBackButton ? 'space-between' : 'center'}
        alignItems="center"
        spacing={2}
        width="100%"
        mt={5}
        maxWidth={theme => theme.spacing(85)}
      >
        {showBackButton && (
          <Button
            sx={{ px: 0 }}
            variant="text"
            color="info"
            startIcon={<IconChevronLeft />}
            onClick={back}
            data-cy="btn-404-back"
          >
            {t('action_return')}
          </Button>
        )}
        <Link href={APP_ROUTES.HOME}>
          <Button
            variant="contained"
            color="secondary"
            data-cy="btn-404-go-home"
          >
            {t('go_to_homepage')}
          </Button>
        </Link>
      </Stack>
    </div>
  );
};

export default NotFoundPage;
